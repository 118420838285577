.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  color: #fff;
  background-color: #3077b4 !important;
  border-color: #3077b4 !important;
  &:hover {
    opacity: 0.9;
  }
}

.btn-outline-primary {
  color: #3077b4;
  border-color: #3077b4 !important;
  &:hover {
    color: #fff;
    background-color: #3077b4 !important;
    border-color: #3077b4 !important;
  }
}

.btn-pill {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
}

.btn-rounded {
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
}
